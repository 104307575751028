import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite'
import {
    EuiCard,
    EuiDescriptionList,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import {StoreContext} from './Slicing'

const ItemsSummary = () => {
    const store = useContext(StoreContext);

    const getDescriptionList = () => {
        return [{
            description: `${store.activeItem.description}`,
            title: <p>Unique Carcass Tag: {store.activeItem.tag}</p>
        }]
    };


    return (
        <EuiFlexGroup direction='column'>

            <EuiCard textAlign='left' title={''}>

                <EuiFlexItem>
                    <EuiText grow={false}>
                        <p>Please provide your request for the following:</p>
                    </EuiText>
                    <EuiHorizontalRule margin="xs"/>

                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiDescriptionList className='order' listItems={getDescriptionList()} compressed/>
                </EuiFlexItem>
            </EuiCard>
            <EuiSpacer/>
        </EuiFlexGroup>
    )
}

export default observer(ItemsSummary)