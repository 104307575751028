import {flow, getSnapshot, types} from 'mobx-state-tree'
import axios from "axios";

const rpc_url = `${process.env.REACT_APP_API_ENDPOINT}`

const OrderItem = types.model('OrderItem', {
    price: types.maybeNull(types.number),
    description: types.string,
    tag: types.identifier
}).views(self => ({
    get currency() {
        if (!self.price) return null
        return `R ${self.price.toFixed(2)}`
    }
}))

const SlicingRequestItem = types.model('SlicingRequestItem', {
    tag: types.string,
    customerName: types.maybeNull(types.string),
    contactNumber: types.maybeNull(types.string),
    bookSlicingTime: types.maybeNull(types.string),
    offals: types.maybeNull(types.string),
    carcass: types.maybeNull(types.string),
    leg1: types.maybeNull(types.string),
    leg2: types.maybeNull(types.string),
    shoulder1: types.maybeNull(types.string),
    shoulder2: types.maybeNull(types.string),
    chops: types.maybeNull(types.string),
    ribs: types.maybeNull(types.string),
    neck: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    addressJsonString: types.maybeNull(types.string),
    addressDetails: '',
    notes: types.maybeNull(types.string)
}).actions(self => ({
    setOption(option, value) {
        self[option] = value
        if (option === 'carcass' && value === 'Distribute') {
            self.resetOptions()
        }

        if (option === 'bookSlicingTime' && value === "Yes") {
            self.resetOptions()
            self.carcass = null;
        }
    },
    resetOptions() {
        self.leg1 = null
        self.leg2 = null
        self.shoulder1 = null
        self.shoulder2 = null
        self.chops = null
        self.ribs = null
        self.neck = null
        self.address = null
        self.addressJsonString = null
        self.addressDetails = ''
        self.notes = null
    }
}))
    .views(self => ({
        get valid() {
            if (!self.customerName || !self.contactNumber) return false;

            if (self.offals && self.carcass === 'Distribute') {
                return true
            }

            if (self.bookSlicingTime === 'Yes') {
                return !!self.offals
            }


            if (self.offals && self.carcass && self.leg1 && self.leg2 && self.shoulder1 && self.shoulder2 && self.chops && self.ribs && self.neck && self.address) {
                return true
            }

            return false
        }
    }))

export const SlicingRequest = types
    .model("SlicingRequest", {
        order_id: types.string,
        items: types.array(OrderItem),
        name: types.string,
        contactNumber: types.string,
        reference: types.string,
        step: 1,
        activeItem: types.maybe(OrderItem),
        activeSlicingRequest: types.maybe(SlicingRequestItem),
        slicingRequests: types.array(SlicingRequestItem),
        saving: false
    })
    .actions(self => ({
        done() {
            self.setStep(1);
            self.activeItem = undefined;
            self.activeSlicingRequest = undefined;
            self.saving = false;
        },
        setStep(step) {
            self.step = step
        },
        setActiveItem(item) {
            self.activeItem = {...item} // shallow copy
            self.copySlicingRequest(item.tag, item.tag);
        },
        copySlicingRequest(sourceTag, destinationTag) {
            const previousRequestForItem = self.slicingRequests.find(req => req.tag === sourceTag)

            const address = previousRequestForItem?.addressJsonString ? JSON.parse(previousRequestForItem.addressJsonString) : undefined
            self.activeSlicingRequest = previousRequestForItem ? {
                ...previousRequestForItem,
                customerName: previousRequestForItem.customerName || self.name,
                contactNumber: previousRequestForItem.contactNumber || self.contactNumber,
                tag: destinationTag,
                address: address?.label,
            } : {
                tag: destinationTag,
                customerName: self.name,
                contactNumber: self.contactNumber
            }
        },
        save: flow(function* save() {
            self.saving = true;
            const body = getSnapshot(self)
            yield axios.post(`${rpc_url}/Slicing/save`, {
                order_id: body.order_id,
                slicing_request: body.activeSlicingRequest
            })

            self.saving = false;

            self.slicingRequests = [
                ...self.slicingRequests.filter(req => req.tag !== self.activeSlicingRequest.tag),
                {...self.activeSlicingRequest}
            ];
        })
    }))
    .views(self => ({
        copyableSlicingRequests(destinationTag) {
            return self.slicingRequests.filter(item => item.tag !== destinationTag)
        },
        filterItems(tag) {
            return self.items.filter(item => tag === item.tag)
        },
        hasBeenSubmitted(tag) {
            return Object.keys(self.previousSubmission(tag)).length > 0;
        },
        previousSubmission(tag) {
            return self.slicingRequests.find(item => tag === item.tag) || {};
        },
        requiresBooking() {
            return self.slicingRequests.some(req => {
                return req.bookSlicingTime === 'Yes';
            })
        },
        allHasBeenSubmitted() {
            return self.items.every(item => {
                return self.hasBeenSubmitted(item.tag);
            })
        }
    }))


export default types
    .model("SlicingForm", {
        isLoading: true,
        errorLoading: false,
        slicingRequest: types.maybe(SlicingRequest)
    })
    .actions(self => {

        const load = flow(function* load(order_id) {
            self.isLoading = true
            self.isError = false

            try {
                const result = yield axios.post(`${rpc_url}/Slicing/load`, {
                    order_id: order_id.toLowerCase()
                })

                self.slicingRequest = result.data

                // self.slicingRequest = {
                //     "order_id": "DbrrGwC2",
                //     "reference": "A-X01-03",
                //     "name": "Rehana Moolla",
                //     "contactNumber": "0734143767",
                //     "items": [
                //         {
                //             "tag": "1998",
                //             "description": "Long Tail Lamb (+/- 22kg carcass)",
                //             // "price": 3100.0
                //         },
                //         {
                //             "tag": "1999",
                //             "description": "Long Tail Lamb (+/- 22kg carcass)",
                //             // "price": 3100.0
                //         }
                //     ],
                //     "slicingRequests": [
                //         // {
                //         //     "tag": "1998",
                //         //     "customerName": "Imtiaz Mangerah",
                //         //     "contactNumber": "0734143767",
                //         //     "offals": "Distribute",
                //         //     "carcass": "Deliver",
                //         //     "leg1": "Slice",
                //         //     "leg2": "Roast",
                //         //     "shoulder1": "Slice",
                //         //     "shoulder2": "Roast",
                //         //     "chops": "Thin",
                //         //     "ribs": "Breast Mutton",
                //         //     "neck": "Curry Pieces",
                //         //     "address": "90 Oakleigh Drive, Musgrave, Berea, South Africa",
                //         //     "addressJsonString": "{\"label\":\"90 Oakleigh Drive, Musgrave, Berea, South Africa\",\"value\":{\"description\":\"90 Oakleigh Drive, Musgrave, Berea, South Africa\",\"matched_substrings\":[{\"length\":2,\"offset\":0},{\"length\":3,\"offset\":3}],\"place_id\":\"ChIJu5nitKoA9x4RP43bbTJ5djU\",\"reference\":\"ChIJu5nitKoA9x4RP43bbTJ5djU\",\"structured_formatting\":{\"main_text\":\"90 Oakleigh Drive\",\"main_text_matched_substrings\":[{\"length\":2,\"offset\":0},{\"length\":3,\"offset\":3}],\"secondary_text\":\"Musgrave, Berea, South Africa\"},\"terms\":[{\"offset\":0,\"value\":\"90\"},{\"offset\":3,\"value\":\"Oakleigh Drive\"},{\"offset\":19,\"value\":\"Musgrave\"},{\"offset\":29,\"value\":\"Berea\"},{\"offset\":36,\"value\":\"South Africa\"}],\"types\":[\"premise\",\"geocode\"]}}",
                //         //     "addressDetails": "Test",
                //         //     "notes": "Test"
                //         // }
                //     ]
                // }

            } catch (err) {
                self.isError = true
                console.log(err)
            }
            self.isLoading = false
        })

        return {
            load,
        }
    })