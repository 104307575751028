import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {
    EuiButton,
    EuiButtonGroup,
    EuiFieldText,
    EuiForm,
    EuiFormHelpText,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiTextArea,
    EuiTextColor
} from "@elastic/eui";
import {StoreContext} from "./Slicing";
import ItemsSummary from "./ItemsSummary";

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const slicingOptions = [
    {
        name: 'Leg 1',
        key: 'leg1',
        options: ['Slice', 'Roast', 'Cubed']
    },
    {
        name: 'Leg 2',
        key: 'leg2',
        options: ['Slice', 'Roast', 'Cubed']
    },
    {
        name: 'Shoulder 1',
        key: 'shoulder1',
        options: ['Slice', 'Roast', 'Cubed']
    },
    {
        name: 'Shoulder 2',
        key: 'shoulder2',
        options: ['Slice', 'Roast', 'Cubed']
    },
    {
        name: 'Chops',
        key: 'chops',
        options: ['Thin', 'Medium', 'Thick']
    },
    {
        name: 'Ribs',
        key: 'ribs',
        options: ['Breast Mutton', 'Spare Ribs']
    },
    {
        name: 'Neck',
        key: 'neck',
        options: ['Chops', 'Curry Pieces']
    }
]

const SlicingRequestForm = observer(() => {
    const store = useContext(StoreContext);
    const [address, setAddress] = React.useState(store.activeSlicingRequest.addressJsonString ? JSON.parse(store.activeSlicingRequest.addressJsonString) : null)

    const handleClick = () => {
        setAddress('')
        {
            store.save()
            store.setStep(3)
        }
    }

    const renderSlicingAndDeliveryUI2 = () => {
        return <>

            {
                slicingOptions.map(option => (
                    <EuiFormRow
                        label={`How should we slice ${option.name}?`}
                        fullWidth
                        className="form-label"
                        key={option.key}
                    >


                        <EuiButtonGroup
                            legend=""
                            options={option.options.map(option => ({id: option, label: option}))}
                            idSelected={store.activeSlicingRequest[option.key]}
                            onChange={(id) => {
                                store.activeSlicingRequest.setOption(option.key, id)
                            }}
                            color="secondary"
                            buttonSize='m'
                            isFullWidth
                        />

                    </EuiFormRow>
                ))
            }

            <EuiFormRow
                label="What address should we deliver to? (Stanger / Durban / Close Surrounding Areas)"
                fullWidth
                className="form-label"
            >


                <GooglePlacesAutocomplete
                    apiKey='AIzaSyCZhWTuw5GV8N71acR1KZ4eJpOnNc5WqnM'
                    autocompletionRequest={{
                        componentRestrictions: {country: ['za']}
                    }}
                    selectProps={{
                        value: address,
                        onChange: (v) => {
                            setAddress(v);
                            store.activeSlicingRequest.setOption('address', v?.label)
                            store.activeSlicingRequest.setOption('addressJsonString', JSON.stringify(v))
                        },
                        placeholder: "Type to search for your address...",
                        isClearable: true
                    }}
                    withSessionToken
                />

            </EuiFormRow>
            <EuiFormRow
                label="Provide any additional address information (complex / unit / contact number)"
                fullWidth
                className="form-label"
            >


                <EuiFieldText
                    name="first"
                    icon='tokenGeo'
                    value={store.activeSlicingRequest.addressDetails}
                    onChange={(e) => store.activeSlicingRequest.setOption('addressDetails', e.target.value)}
                    fullWidth/>

            </EuiFormRow>

            <EuiFormRow
                label="Tell us if you have any special requests for your slicing requirements"
                fullWidth
                className="form-label"
            >
                <EuiTextArea
                    placeholder="Type special slicing requests here. We will try our best to accommodate this if we are able to"
                    value={store.activeSlicingRequest.notes}
                    onChange={(e) => store.activeSlicingRequest.setOption('notes', e.target.value)}
                    fullWidth
                />

            </EuiFormRow>
        </>
    }

    return <>
        <EuiSpacer/>
        <EuiFormRow
            label="Provide a name or reference for this carcass"
            fullWidth
            className="form-label"
        >
            <EuiFieldText
                name="customerName"
                icon='user'
                value={store.activeSlicingRequest.customerName}
                onChange={(e) => store.activeSlicingRequest.setOption('customerName', e.target.value)}
                fullWidth/>

        </EuiFormRow>

        <EuiFormRow
            label="Provide a contact number for this carcass"
            fullWidth
            className="form-label"
        >
            <EuiFieldText
                name="contactNumber"
                icon='bell'
                value={store.activeSlicingRequest.contactNumber}
                onChange={(e) => store.activeSlicingRequest.setOption('contactNumber', e.target.value)}
                fullWidth/>
        </EuiFormRow>

        <EuiFormRow
            label={`Would you like to be present in person during slicing?`}
            fullWidth
            className="form-label"
        >
            <EuiButtonGroup
                legend=""
                options={[{id: 'Yes', label: 'Yes'}, {id: 'No', label: 'No'}]}
                idSelected={store.activeSlicingRequest.bookSlicingTime}
                onChange={(id) => {
                    store.activeSlicingRequest.setOption('bookSlicingTime', id)
                }}
                color="secondary"
                buttonSize='m'
                isFullWidth
            />
        </EuiFormRow>

        {(store.activeSlicingRequest.bookSlicingTime === 'Yes' || store.activeSlicingRequest.bookSlicingTime === 'No') && <>


            {
                store.activeSlicingRequest.bookSlicingTime === 'No' && <>
                    <EuiFormRow
                        label="Do you want us to distribute your meat or have it delivered to your door?"
                        fullWidth
                        className="form-label"
                    >
                        <EuiSelect
                            options={[
                                {value: "Distribute", text: 'Distribute to local organization'},
                                {value: "Deliver", text: 'Deliver to an address provided'}
                            ]}
                            value={store.activeSlicingRequest.carcass}
                            onChange={(e) => {
                                store.activeSlicingRequest.setOption('carcass', e.target.value)
                            }}
                            prepend="Select"
                            hasNoInitialSelection
                            fullWidth
                        />


                    </EuiFormRow>
                </>
            }

            {
                store.activeSlicingRequest.bookSlicingTime === 'Yes' && <>
                    <EuiFormHelpText color="subdued">You will be provided with details on how to book a timeslot after
                        completing this form</EuiFormHelpText>
                    <EuiSpacer></EuiSpacer>

                </>
            }
            <EuiFormRow
                label="What should we do with the animals offals,head and legs?"
                helpText="Due to logistical constraints we are unable to store or deliver offals, head and legs this eid. We offer you the option to collect it on the day of slaughter or alternatively we can distribute it on your behalf."
                fullWidth
                className="form-label"
            >
                <EuiSelect
                    options={[
                        {value: "Distribute", text: 'Please distribute this for me'},
                        {value: "Collect", text: 'I will collect it on the day of slaughter'}
                    ]}
                    value={store.activeSlicingRequest.offals}
                    onChange={(e) => {
                        store.activeSlicingRequest.setOption('offals', e.target.value)
                    }}
                    prepend="Select"
                    hasNoInitialSelection
                    fullWidth
                />

            </EuiFormRow>

            {
                store.activeSlicingRequest.carcass === 'Deliver' && renderSlicingAndDeliveryUI2()
            }

            <EuiSpacer></EuiSpacer>
        </>}


        {store.activeSlicingRequest.valid ?
            <EuiButton onClick={handleClick} fill>Submit</EuiButton> :
            <EuiTextColor color="warning"><p>Please complete all information above</p></EuiTextColor>}

    </>
})

export const StepTwo = observer(() => {

    return <>
        <ItemsSummary/>
        <EuiForm component="form">
            <SlicingRequestForm/>
        </EuiForm>
    </>
})