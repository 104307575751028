import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from "react-router-dom";

import SlicingFormStore from '../stores/Slicing';
import {StepOne} from "./StepOne";
import {StepTwo} from "./StepTwo";
import {StepThree} from "./StepThree";

import {EuiEmptyPrompt, EuiImage, EuiPageTemplate, EuiProgress, EuiSpacer} from "@elastic/eui";
import Loading from "./Loading";
import {getSnapshot} from "mobx-state-tree";
import {OrderSummaryPage} from "./OrderSummaryPage";


export const StoreContext = React.createContext(null);

const rootStore = SlicingFormStore.create()


const Steps = observer(() => {
    const store = useContext(StoreContext);
    const {order_id} = useParams();

    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [store.step])
    if (!store.activeItem) {
        return  <OrderSummaryPage />
    }

    const hasCopyableRequests = store.copyableSlicingRequests(store.activeItem.tag).length >= 1

    if (store.step === 1 && hasCopyableRequests) {
        return <StepOne rootStore={rootStore} orderId={order_id}/>
    } else if (store.step === 2 || (store.step === 1 && !hasCopyableRequests)) {
        return <StepTwo rootStore={rootStore} orderId={order_id}/>
    } else if (store.step === 3) {
        return <StepThree rootStore={rootStore} orderId={order_id}/>
    }


    return <p>Something went wrong. Please contact us via WhatsApp for assistance.</p>;
})


const Slicing = () => {
    const {order_id} = useParams();

    React.useEffect(() => {
        rootStore.load(order_id)
    }, [order_id])

    const renderContent = () => {

        if (rootStore.isLoading) {
            return <Loading/>
        }

        if (rootStore.isError) {
            return <EuiEmptyPrompt
                iconType="editorStrike"
                title={<h2>Unable to Load your Order</h2>}
                body={
                    <>
                        <p>
                            We are unable to load your order. Please try refreshing the page.
                        </p>
                        <p>
                            If the problem persists, contact us on
                            <a href="https://wa.me/27734143767"
                               target="_blank"
                               rel="noreferrer">
                                WhatsApp
                            </a>
                        </p>
                    </>
                }
            />
        }

        return (
            <>

                <Steps/>
                {/*<pre>{JSON.stringify(getSnapshot(rootStore), null, 2)}</pre>*/}
            </>
        )

    }

    return (
        <EuiPageTemplate
            // restrictWidth="50%"
            template="empty"
        >
            <EuiImage
                hasShadow
                size="fullWidth"
                alt="KWA Justice Livestock"
                src="/img/KJLBanner.png"
            />
            <EuiSpacer/>
            <StoreContext.Provider value={rootStore.slicingRequest}>
                {renderContent()}
            </StoreContext.Provider>

        </EuiPageTemplate>
    );
};

export default observer(Slicing)