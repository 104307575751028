import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "./Slicing";
import {EuiEmptyPrompt, EuiLink} from "@elastic/eui";

export const BookTimeSlot = observer(({}) => {

    const store = useContext(StoreContext);

    if (!store.requiresBooking() || !store.allHasBeenSubmitted())
        return null


    return (
        <EuiEmptyPrompt
            actions={
                <EuiLink color="successs" fill href="https://wa.me/27837864720" target="_blank"
                         rel="noreferrer">
                    WhatsApp to book a timeslot
                </EuiLink>
            }
            body={
                <p>You require a slicing timeslot. This can be done
                    on the day of slaughter if you are present at the farm, or via WhatsApp using the link
                    below. Please ensure you complete all slicing requests below.</p>
            }
        />
    )
})