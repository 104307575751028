import {observer} from "mobx-react-lite";
import React from "react";
import {EuiForm, EuiSpacer, EuiText} from "@elastic/eui";
import OrderSummary from "./OrderSummary";


export const OrderSummaryPage = observer(() => {
    return <>
        <EuiText><h3>Please complete your slicing and delivery request using the form below</h3></EuiText>
        <EuiText>If multiple customers are linked to the same order, please share this link with them and request them to fill out the request for their animal</EuiText>
        <EuiSpacer/>
        <OrderSummary/>
        <EuiForm component="form">
        </EuiForm>
    </>
})