import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {
    EuiButton,
    EuiCard,
    EuiDescriptionList,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiSpacer,
    EuiStat,
    EuiText
} from "@elastic/eui";
import {StoreContext} from "./Slicing";
import Loading from "./Loading";
import {BookTimeSlot} from "./BookTimeSlot";


const deliveryFields = [
    {key: 'leg1', text: 'Leg 1'},
    {key: 'leg2', text: 'Leg 2'},
    {key: 'shoulder1', text: 'Shoulder 1'},
    {key: 'shoulder2', text: 'Shoulder 2'},
    {key: 'chops', text: 'Chops'},
    {key: 'ribs', text: 'Ribs'},
    {key: 'neck', text: 'Neck'},
    {key: 'address', text: 'Address'},
    {key: 'addressDetails', text: 'Address Details'},
    {key: 'notes', text: 'Notes'},
]


const Home = ({rootStore, orderId}) => {
    const store = useContext(StoreContext);
    const hasMultipleItems = store.items.length > 1;
    return (
        <div><EuiButton onClick={() => {
            store.done();
            rootStore.load(orderId)

        }} fill>{hasMultipleItems ? "View All Slicing Requests" : "Home"}</EuiButton></div>
    );
}

const SlicingSpec = ({spec}) => {
    const renderDeliveryFields = () => deliveryFields.map(field => (
        <EuiFlexItem key={field.key}>
            <EuiStat title={spec[field.key]} description={field.text} titleSize="xs"/>
        </EuiFlexItem>
    ))
    return (
        <div className="pad-left-20">
            {spec.bookSlicingTime === 'Yes' && <EuiFlexItem>
                <EuiStat title={spec.bookSlicingTime} description="Book slicing timeslot" titleSize="xs"/>
            </EuiFlexItem>}
            {spec.carcass && <EuiFlexItem>
                <EuiStat title={spec.carcass} description="Meat" titleSize="xs"/>
            </EuiFlexItem>}
            <EuiFlexItem>
                <EuiStat title={spec.offals} description="Offals / Head / Legs" titleSize="xs"/>
            </EuiFlexItem>
            {
                spec.carcass === 'Deliver' && renderDeliveryFields()
            }
        </div>
    )
}

export const StepThree = observer(({rootStore, orderId}) => {

    const store = useContext(StoreContext);
    const getDescriptionList = (appliesTo) => {
        return store.filterItems(appliesTo).map(item => ({
            title: `${item.description}` + (item.currency ? ` @ ${item.currency}` : ''),
            description: <p>Unique Carcass Tag: <b>{item.tag}</b></p>
        }))
    };

    const renderSlicingGroups = () => (
        store.slicingRequests.map((req, idx) => (
            <EuiFlexItem key={idx}>
                <p><b>Slicing Request for carcass: {req.tag}</b></p>
                <p><b>Customer</b>: {req.customerName}</p>
                <EuiSpacer/>
                <EuiDescriptionList className='order' listItems={getDescriptionList(req.tag)} compressed/>
                <EuiSpacer/>
                <SlicingSpec spec={req}/>
                <EuiHorizontalRule/>
            </EuiFlexItem>
        ))
    )
    if (store.saving)
        return <Loading/>


    return (
        <EuiFlexGroup direction='column'>
            <EuiSpacer/>
            <EuiText><h3 color="success">Thank you, your request was saved. Use the button below to go back to the list
                of slicing requests or to modify your request</h3></EuiText>
            <EuiCard textAlign='left' title=''>

                <EuiFlexItem>
                    <EuiText grow={false}>
                        <h2>Your Slicing Request Summary</h2>
                        <p>Order for: <b>{store.name}</b>< br/>Ref: <b>{store.reference}</b></p>
                    </EuiText>
                    <Home rootStore={rootStore} orderId={orderId}/>
                    <EuiHorizontalRule margin="xs"/>

                </EuiFlexItem>
                <BookTimeSlot/>
                {renderSlicingGroups()}
                <Home rootStore={rootStore} orderId={orderId}/>
            </EuiCard>
            <EuiSpacer/>
        </EuiFlexGroup>
    )
})