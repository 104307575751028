import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {EuiButton, EuiForm, EuiFormRow, EuiSelect, EuiSpacer, EuiText} from "@elastic/eui";
import {StoreContext} from "./Slicing";
import ItemsSummary from "./ItemsSummary";


const getCopyableOptions = (copyableSlicingRequests) => {
    return copyableSlicingRequests.map(request => ({
        value: request.tag,
        text: `Start with values from carcass tag ${request.tag}`
    }))
}

export const StepOne = observer(() => {
    const store = useContext(StoreContext);
    const [value, setValue] = React.useState(null);
    const copyableSlicingRequests = store.copyableSlicingRequests(store.activeItem.tag)
    return <>
        <EuiText><h3>Please complete your slicing and delivery request using the form below</h3></EuiText>
        <EuiSpacer/>
        <ItemsSummary/>
        <EuiForm component="form">

            {copyableSlicingRequests.length >= 1 && <>

                <EuiSpacer/>
                <EuiFormRow
                    label="Would you like to copy a slicing request from one of your other submissions or start fresh?"
                    className="form-label"
                    helpText="You have multiple items in your order. You can choose copy one of your previous submissions to speed up the process. You will be able to customize the request further before submitting"
                    fullWidth>
                    <EuiSelect
                        options={[
                            {value: "No", text: store.activeSlicingRequest.offals ? 'Modify existing request' : 'Start with a blank request'},
                            ...getCopyableOptions(copyableSlicingRequests)
                        ]}
                        value={value}
                        onChange={(e) => {
                            const newVal = e.target.value;
                            setValue(newVal)
                            const shouldCopyPreviousSubmission = newVal !== 'No';
                            const sourceTag = newVal;
                            const destinationTag = store.activeSlicingRequest.tag
                            {
                                shouldCopyPreviousSubmission && store.copySlicingRequest(sourceTag, destinationTag)
                            }
                        }}
                        prepend="Select"
                        hasNoInitialSelection
                        fullWidth
                    />
                </EuiFormRow>

                <EuiSpacer/>
            </>

            }


            {value && <EuiButton onClick={() => store.setStep(2)} fill>Continue</EuiButton>}

        </EuiForm>
    </>
})