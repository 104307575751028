import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite'
import {
    EuiButton,
    EuiCard,
    EuiFlexGrid,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import {StoreContext} from './Slicing'
import {BookTimeSlot} from "./BookTimeSlot";


export const Item = ({item, cta = true}) => {
    const store = useContext(StoreContext);

    const hasBeenSubmitted = store.hasBeenSubmitted(item.tag)
    const previousSubmission = store.previousSubmission(item.tag)

    return (
        <div className="item-container">
            <div className="item-container-summary">
                <p><b>{`${item.description}` + (item.currency ? ` @ ${item.currency}` : '')}</b></p>
                <p>Unique Carcass Tag: <b>{item.tag}</b></p>
            </div>
            {
                cta && <div className="item-container-actions">
                <p>{hasBeenSubmitted ? `A slicing request for this animal has been submitted for ${previousSubmission?.customerName}` : 'Please submit a slicing request for this carcass'}</p>
                <br />
                <EuiButton
                    onClick={() => store.setActiveItem(item)}
                    fill
                    color={hasBeenSubmitted ? 'success' : 'primary'}
                    iconType={hasBeenSubmitted ? 'documentEdit' : 'listAdd'}
                >
                    {hasBeenSubmitted ? 'Edit Slicing Request' : 'Create Slicing Request'}
                </EuiButton>

            </div>
            }
        </div>
    );
}


const OrderSummary = ({only}) => {
    const store = useContext(StoreContext);

    const renderItems = () => {
        return store.items.filter(item => only ? item.tag === only : true).map(item => <EuiFlexItem key={item.tag}>
            <Item item={item} cta={only === undefined}></Item></EuiFlexItem>);
    };

    return (
        <EuiFlexGroup direction='column'>

            <EuiCard textAlign='left' title=''>

                <EuiFlexItem>
                    <EuiText grow={false}>
                        <h4>Your order summary</h4>
                        <p>Order for: <b>{store.name}</b>< br/>Ref: <b>{store.reference}</b></p>
                    </EuiText>
                    <EuiHorizontalRule margin="xs"/>

                </EuiFlexItem>
                <p>{
                    store.allHasBeenSubmitted() ? <BookTimeSlot/> : null
                }</p>
                <EuiFlexGrid columns={1} direction="column">
                    {renderItems()}
                </EuiFlexGrid>

            </EuiCard>
            <EuiSpacer/>
        </EuiFlexGroup>
    )
}

export default observer(OrderSummary)