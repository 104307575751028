import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import SlicingForm from "./slicing/Slicing";
import React from "react";
import '@elastic/eui/dist/eui_theme_light.css';
import './custom_styles.sass';

export default () => (<Router>
    <Switch>
        <Route path="/:order_id" exact>
            <SlicingForm/>
        </Route>
        <Route path="/">
            <div><p>Page not found</p></div>
        </Route>
    </Switch>
</Router>
)